import { db } from "../firebaseInit";
import {
  get,
  child,
  ref,
  set,
  remove,
  orderByChild,
  query,
  equalTo,
  update,
  startAt,
  endAt,
} from "firebase/database";
import {
  ConvertedParticipants,
  IFirebaseParticipant,
} from "./Interfaces/Participants";
import {
  calcPercentage,
  convertIdCheckToArray,
  convertObjectIntoArrayOfObject,
  filterObjectArrayBasedOnKey,
  getKeySumFromAnArrayOfObject,
  sortObjectBasedOnReqKey,
  getLastEntryFromAnArrayOfObject,
} from "@/utils/Common";
import { ADD_PARTICIPANT_RESPONSE } from "@/utils/Constants";
import moment from "moment-timezone";
const dbRef = ref(db);

/**
 * Common Fetch data
 */
export const getData = async <T>(
  path: string
): Promise<T | null | undefined> => {
  try {
    const data = await get(query(ref(db, path)));
    return data.exists() ? data.val() : null;
  } catch (error) {
    if (error) throw error;
  }
};

/**
 * Users API's
 * @param data
 * @param path
 * @param id
 * @returns
 */
export const addData = async (userId: string, data: any) => {
  try {
    const response = await set(ref(db, "users/" + userId), data);
    return response;
  } catch (error) {
    if (error) throw error;
  }
};

export const updateUser = async <T>(
  key: string,
  data: ConvertedParticipants
) => {
  try {
    await update(ref(db, "users/" + `${key}`), data);
    return true;
  } catch (err) {
    if (err) throw err;
  }
};

export const getUsersWithFilter = async <T>(
  path: string,
  filters: string
): Promise<T | null> => {
  try {
    const arr = [];
    if (filters) {
      arr.push(orderByChild("role"));
      arr.push(equalTo(filters));
    }
    const data = await get(query(ref(db, path), ...arr));
    return data.exists() ? data.val() : null;
  } catch (error) {
    throw new Error();
  }
};

export const getUser = async (path: string, key: string) => {
  try {
    const data = await get(child(dbRef, `${path}${key}`));
    return data.exists() ? data.val() : null;
  } catch (error) {
    if (error) throw error;
  }
};

export const deleteUser = async (Id: string) => {
  try {
    const userRef = ref(db, `users/${Id}`);
    await remove(userRef);
    return "success";
  } catch (error) {
    if (error) throw error;
  }
};

/**
 * Studies API's
 * @param data
 * @param path
 * @param id
 * @returns
 */

export const addStudies = async (data: any, path: string, id: string) => {
  try {
    return set(ref(db, path + id), data);
  } catch (error) {
    if (error) throw error;
  }
};

export const getStudyById = async (path: string, key: string) => {
  try {
    const data = await get(query(ref(db, `${path}${key}`)));
    return data.exists() ? data.val() : null;
  } catch (error) {
    throw new Error();
  }
};

export const getFilteredStudy = async <T>(
  path: string,
  filters: string
): Promise<T | null> => {
  try {
    const arr = [];
    if (filters) {
      arr.push(orderByChild("status"));
      arr.push(equalTo(filters));
    }
    const data = await get(query(ref(db, path), ...arr));
    return data.exists() ? data.val() : null;
  } catch (error) {
    throw new Error();
  }
};

/**
 * Participants API's
 * @param data
 * @param path
 * @param id
 * @returns
 */

export const addParticipant = async (
  data: IFirebaseParticipant,
  path: string,
  id: string
) => {
  try {
    const arr = [];
    arr.push(orderByChild("pin"));
    arr.push(equalTo(data.pin));
    const pinCheck = await get(query(ref(db, path), ...arr));
    if (!pinCheck.exists()) {
      const idArray = [];
      idArray.push(orderByChild("id"));
      idArray.push(equalTo(data.id));
      const idCheck = await get(query(ref(db, path), ...idArray));
      if (idCheck.exists()) {
        const dat = convertIdCheckToArray(idCheck.val(), data.studyId);
        if (dat) {
          throw new Error(ADD_PARTICIPANT_RESPONSE.IdExist);
        }
      }
      const filteredData: IFirebaseParticipant = Object.keys(data).reduce(
        (acc: any, key) => {
          if (typeof data[key] !== "undefined") {
            acc[key] = data[key];
          }
          return acc;
        },
        {}
      );

      const response = await set(ref(db, `${path}` + id), filteredData);
      return {
        flag: true,
        message: ADD_PARTICIPANT_RESPONSE.Successfully,
        data: response,
      };
    } else {
      throw new Error(ADD_PARTICIPANT_RESPONSE.PinExist);
    }
  } catch (error: any) {
    if (error.message) {
      throw new Error(error.message);
    }
    throw new Error(ADD_PARTICIPANT_RESPONSE.SomethingWentWrong);
  }
};

export const editParticipant = async (
  data: ConvertedParticipants,
  path: string,
  id: string
) => {
  try {
    const response = await set(ref(db, path + id), data);
    return {
      flag: false,
      message: ADD_PARTICIPANT_RESPONSE.Successfully,
      data: response,
    };
  } catch (error) {
    if (error) throw error;
  }
};

export const getParticipants = async (path: string, key: string) => {
  try {
    const arr = [];
    arr.push(orderByChild("studyId"));
    arr.push(equalTo(key));
    const data = await get(query(ref(db, path), ...arr));
    return data.exists() ? data.val() : null;
  } catch (error) {
    throw new Error();
  }
};

export const getParticipantById = async (path: string, key: string) => {
  try {
    const data = await get(query(ref(db, `${path}${key}`)));
    return data.exists() ? data.val() : null;
  } catch (err) {
    throw new Error();
  }
};

export const getParticipantData = async <T>(path: string) => {
  try {
    const responseData = await get(child(dbRef, path));
    return responseData.exists() ? responseData.val() : null;
  } catch (error) {
    throw new Error();
  }
};

export const updateParticipant = async <T>(
  path: string,
  key: string,
  data: ConvertedParticipants
) => {
  try {
    await update(ref(db, `${path}${key}`), data);
    return true;
  } catch (err) {
    if (err) throw err;
  }
};

/**
 * Chart data API's
 * @param toDate
 * @param fromDate
 */

export const getChartData = async <T>(
  toDate: number,
  fromDate: number,
  path1: string,
  path2: string
) => {
  try {
    const arr = [];
    arr.push(orderByChild("requestedAt"));
    arr.push(startAt(fromDate * 1000));
    arr.push(endAt(toDate * 1000));
    const basalRawData = await get(query(ref(db, path1), ...arr));
    const bolusRawData = await get(
      query(
        ref(db, path2),
        ...[
          orderByChild("requestedAt"),
          startAt(fromDate * 1000),
          endAt(toDate * 1000),
        ]
      )
    );
    const timestampBolusRawData = await get(
      query(
        ref(db, path2),
        ...[
          orderByChild("timestamp"),
          startAt(fromDate * 1000),
          endAt(toDate * 1000),
        ]
      )
    );

    const basalData = basalRawData.exists() ? basalRawData.val() : null;
    const bolusData = bolusRawData.exists() ? bolusRawData.val() : null;
    const zeroRequestedAtData = timestampBolusRawData.exists()
      ? timestampBolusRawData.val()
      : null;
    const filteredObject = await filterObjects(zeroRequestedAtData);
    let tempData = { ...filteredObject, ...bolusData };
    return {
      basalData,
      bolusData: Object.keys(tempData).length === 0 ? null : tempData,
    };
  } catch (error) {
    throw new Error();
  }
};

export const getSystemConfigurationData = async <T>(
  toDate: number,
  fromDate: number,
  path: string,
  path1: string,
  path2: string,
  closedLoopBasal: {
    x: string;
    y: number;
  }[],
  bolus: {
    x: string;
    y: number;
  }[],
  bolusRequestDataWithTimeStampKey: Record<string, any>
) => {
  try {
    const arr = [];
    arr.push(orderByChild("timestamp"));
    arr.push(startAt(fromDate * 1000));
    arr.push(endAt(toDate * 1000));
    const totalDailyDoseRawData = await get(query(ref(db, path1), ...arr));
    const patientConsumptionRawBasalData = await get(
      query(
        ref(db, path),
        ...[
          orderByChild("timestamp"),
          startAt(fromDate * 1000),
          endAt(toDate * 1000),
        ]
      )
    );
    const pumpInfusionRawData = await get(
      query(
        ref(db, path2),
        ...[
          orderByChild("createdAt"),
          startAt(fromDate * 1000),
          endAt(toDate * 1000),
        ]
      )
    );
    const totalDailyDoseData = totalDailyDoseRawData.exists()
      ? totalDailyDoseRawData.val()
      : null;
    const pumpInfusionData = pumpInfusionRawData.exists()
      ? pumpInfusionRawData.val()
      : null;
    const patientConsumptionBasedBasal = patientConsumptionRawBasalData.exists()
      ? patientConsumptionRawBasalData.val()
      : null;
    const pumpInfusionBolusDataArray =
      filterObjectArrayBasedOnKey(
        convertObjectIntoArrayOfObject(pumpInfusionData),
        "isBasal"
      ) ?? [];
    const patientConsumptionBasedBasalArray =
      convertObjectIntoArrayOfObject(patientConsumptionBasedBasal) ?? [];
    const totalConsumptionBasedBasal =
      getKeySumFromAnArrayOfObject(
        patientConsumptionBasedBasalArray,
        "basal"
      ) ?? 0;
    const averageBasal = totalConsumptionBasedBasal / 6;

    const tddParameterBasal =
      getLastEntryFromAnArrayOfObject(
        convertObjectIntoArrayOfObject(totalDailyDoseData),
        "basalTdd"
      ) ?? 0;
    const bolusTDD =
      getLastEntryFromAnArrayOfObject(
        convertObjectIntoArrayOfObject(totalDailyDoseData),
        "bolusTdd"
      ) ?? 0;
    const patientConsumptionBasedBolus =
      getKeySumFromAnArrayOfObject(pumpInfusionBolusDataArray, "bolusAmount") ??
      0;
    const {
      mealTimeBolus,
      outsideMealBolus,
      pumpInfusionBolusDataArrayWithGraphKeys,
    } = findMealAndOutsideMealBolus(
      bolusRequestDataWithTimeStampKey,
      pumpInfusionBolusDataArray
    );
    const totalClosedLoopBasal =
      getKeySumFromAnArrayOfObject(closedLoopBasal, "y") ?? 0;

    const bolusOnMealTime = getKeySumFromAnArrayOfObject(bolus, "y") ?? 0;
    const totalInsulin = averageBasal + patientConsumptionBasedBolus; //bolusOnMealTime + outsideMealBolus;
    // const totalInsulin =
    //   totalClosedLoopBasal / 6 + bolusOnMealTime + outsideMealBolus;

    return {
      tddParameterBasal: Number(tddParameterBasal.toFixed(2)),
      tddParameterBolus: Number(bolusTDD.toFixed(2)),
      totalInsulin: Number(totalInsulin.toFixed(2)),
      percentageBasal: calcPercentage(averageBasal, totalInsulin),
      outsideMealBolusDataArray: pumpInfusionBolusDataArray,
      patientConsumptionBasedBasal: Number(averageBasal.toFixed(2)),
      patientConsumptionBasedBolus: Number(
        patientConsumptionBasedBolus.toFixed(2)
      ),
      pumpInfusionBolusDataArrayWithGraphKeys:
        pumpInfusionBolusDataArrayWithGraphKeys || [],
    };
  } catch (error) {
    throw new Error();
  }
};

export const getExerciseData = async (
  toDate: number,
  fromDate: number,
  path: string
) => {
  try {
    const arr = [];
    arr.push(orderByChild("timestamp"));
    arr.push(startAt(fromDate * 1000));
    arr.push(endAt(toDate * 1000));
    const exerciseRawData = await get(query(ref(db, path), ...arr));

    const exerciseData = exerciseRawData.exists()
      ? exerciseRawData.val()
      : null;

    const sortedExerciseData = sortObjectBasedOnReqKey(
      exerciseData,
      "timestamp"
    );
    return sortedExerciseData;
  } catch (err) {
    throw new Error();
  }
};

const filterObjects = async (obj: any) => {
  const result: any = {};
  if (obj) {
    for (const key in obj) {
      if (obj[key].requestedAt === 0) {
        result[key] = obj[key];
      }
    }
  }
  return result;
};

const findMealAndOutsideMealBolus = (
  bolusRequestDataWithTimeStampKey: Record<string, any>,
  pumpInfusionBolusDataArray: Array<Record<string, any>>
) => {
  let outsideMealBolus = 0;
  let mealTimeBolus = 0;
  const bolusRequestTimeStamps = Object.keys(bolusRequestDataWithTimeStampKey);
  const pumpInfusionBolusDataArrayWithGraphKeys: Array<
    Record<string, any>
  > = [];
  pumpInfusionBolusDataArray.forEach((element) => {
    const matchedBolusRequest = bolusRequestTimeStamps.find((item) => {
      const subtract30Seconds = moment
        .unix(Number(item))
        .subtract(120 * 1000, "seconds")
        .unix();
      const add30Seconds = moment
        .unix(Number(item))
        .add(120 * 1000, "seconds")
        .unix();
      return moment(element.createdAt).isBetween(
        subtract30Seconds,
        add30Seconds
      );
    });
    if (matchedBolusRequest) {
      const bolusData = bolusRequestDataWithTimeStampKey[matchedBolusRequest];
      pumpInfusionBolusDataArrayWithGraphKeys.push({
        createdAt: element?.createdAt,
        bolusAmount: element?.bolusAmount,
        mealCarbAmount: bolusData?.mealCarbAmount,
        algoTotalBolus: bolusData?.algoTotalBolusAmountRounded,
        iob: bolusData?.iob,
        icr: bolusData?.mealCarbAmount > 0 ? bolusData?.icr : 0,
      });
      if (bolusData) {
        if (bolusData.mealCarbAmount > 0) {
          mealTimeBolus += element.bolusAmount;
        } else {
          outsideMealBolus += element.bolusAmount;
        }
      }
    }
  });

  return {
    mealTimeBolus,
    outsideMealBolus,
    pumpInfusionBolusDataArrayWithGraphKeys,
  };
};
