
import SidenavList from "./SidenavList.vue";
const logo = require("@/assets/img/mcgill-logo.png");
const logoDark = require("@/assets/img/logo-ct-dark.png");
const logoCloseSidenav = require("@/assets/img/close-sidenav.png");
import { mapState } from "pinia";
import { settingStore } from "@/store/setting";

export default {
  name: "index",
  components: {
    SidenavList,
  },
  methods: {
    hideSidenav() {
      const sidenav = document.getElementById('sidenav-main');
      if (sidenav) {
        sidenav.classList.add('hide');
      }
    },
    showSidenav() {
      const sidenav = document.getElementById('sidenav-main');
      if (sidenav) {
        sidenav.classList.remove('hide');
      }
    },
  },
  data() {
    return {
      logo,
      logoDark,
      logoCloseSidenav
    };
  },
  computed: {
    ...mapState(settingStore, ["isRTL", "sidebarType", "isDarkMode"]),
  },
};
