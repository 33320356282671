import { participantParser } from "./../utils/Common";
import { nanoid } from "nanoid";
import { defineStore } from "pinia";
import {
  PATH,
  RANDOM_STRING_LENGTH,
  STORE_NAMES,
  EMAIL_DOMAIN,
} from "@/utils/Constants";

import {
  addParticipant,
  getParticipants,
  getParticipantById,
  getParticipantData,
  editParticipant,
  updateParticipant,
} from "../utils/Api";

import { ParticipantInterface, Result } from "@/utils/Interfaces/Participants";

import {
  randomStringGenerator,
  convertInitialParticipantsData,
  convertParticipantToArray,
  transformData,
} from "@/utils/Common";
import { useAuthStore, useChartStore } from "@/store";
interface ParticipantsInterface {
  participants: ParticipantInterface[];
  fetchParticipantDetail: boolean;
  selectedParticipant: ParticipantInterface;
}

export const useStoreParticipants = defineStore(STORE_NAMES.participant, {
  state: () => {
    return {
      participants: [],
      selectedParticipant: {},
      fetchParticipantDetail: false,
    } as ParticipantsInterface;
  },
  actions: {
    async addParticipants<T>(
      data: ParticipantInterface,
      studyKey: string,
      studyName: string,
      edit?: boolean
    ): Promise<T | null | void | {}> {
      const authStore = useAuthStore();
      let id;
      let pin;
      let convertedInput;
      try {
        if (edit) {
          convertedInput = convertInitialParticipantsData({
            ...data,
          });
          id = convertedInput.key;
          delete convertedInput["key"];
          return await editParticipant(convertedInput, PATH.participants, id);
        }
        id = nanoid();
        pin = randomStringGenerator(RANDOM_STRING_LENGTH);
        convertedInput = convertInitialParticipantsData({
          ...data,
          studyId: studyKey,
          pin: pin,
          isLogin: false,
        });
        const response = await addParticipant(
          convertedInput,
          PATH.participants,
          id
        );
        if (response?.flag) {
          const email = `${studyKey}${data.id?.replace(
            / /g,
            ""
          )}${EMAIL_DOMAIN}`;
          authStore.createUser({ email, password: pin }, true);
        }
        return response;
      } catch (err) {
        if (err) throw err;
      }
    },
    async removeParticipants<T>() {
      this.participants = [];
    },
    async changeFetchParticipantDetail<T>(value: boolean) {
      this.fetchParticipantDetail = value;
    },
    async getAllParticipant<T>(key: string, studyName?: string): Result<T> {
      try {
        const chartStore = useChartStore();
        const allParticipants = await getParticipants(PATH.participants, key);

        if (allParticipants) {
          const convertedData = convertParticipantToArray(
            participantParser(allParticipants)
          );

          // for sorting in the alphanumeric menu
          convertedData.sort((a, b) =>
            (a?.id || "").localeCompare(b.id || "", "en", { numeric: true })
          );

          const data1: any = transformData(convertedData);
          if (studyName) {
            if (this.fetchParticipantDetail) {
              var results: any = await Promise.all(
                data1.map(async (item: any) => {
                  if (item.interventionLatest) {
                    const data = await chartStore.getOverviewData(
                      [
                        item?.interventionLatest?.startDate?.toString() || "",
                        item?.interventionLatest?.endDate?.toString() || "",
                      ],
                      item.key as string,
                      studyName,
                      item.id as string,
                      true
                    );
                    return { ...data, ...item };
                  } else {
                    return { ...item };
                  }
                })
              );
              const studyParticipants = results;
              this.participants = studyParticipants;
              return studyParticipants;
            }
            this.participants = data1;
            return data1;
          }
          this.participants = convertedData;
          return null;
        }
        this.participants = [];
        return null;
      } catch (error) {
        if (error) throw error;
      }
    },
    async getParticipantWithId<T>(key: string): Result<T> {
      try {
        const data = await getParticipantById(PATH.participants, key);
        if (data) {
          const convertedData = convertParticipantToArray(
            participantParser({
              [key]: data,
            })
          );
          this.selectedParticipant = { ...convertedData[0] };

          const index = this.participants.findIndex(
            (obj) => obj.id === convertedData[0].id
          );

          // If a matching object was found, update its properties
          if (index !== -1) {
            this.participants[index].isLogin =
              convertedData[0]?.isLogin || false;
          }

          return null;
        }
        return null;
      } catch (error) {
        if (error) throw error;
      }
    },
    async getParticipantDataById<T>(id: string): Result<T> {
      try {
        let path = PATH.chartData
          .replace("@id", `${id}`)
          .replace("BasalLog/", "");
        const data = await getParticipantData(path);
        if (data) {
          return data;
        }
        return null;
      } catch (error) {
        if (error) throw error;
      }
    },
    async participantUpdate<T>(
      key: string,
      data: any
    ): Promise<T | null | void | boolean> {
      try {
        const response = await updateParticipant(PATH.participants, key, data);
        if (response) {
          await this.getParticipantWithId(key);
        }
        return response;
      } catch (error) {
        if (error) throw error;
      }
    },
    async forwardParticipant<T>() {
      const currentIndex = this.participants.findIndex(
        (participant) => participant.key === this.selectedParticipant.key
      );
      const nextIndex = currentIndex + 1;
      if (nextIndex >= this.participants.length) {
        const forwardParticipant = this.participants[0];
        this.selectedParticipant = forwardParticipant;
        return forwardParticipant;
      }
      const nextParticipant = this.participants[nextIndex];
      this.selectedParticipant = nextParticipant;
      return nextParticipant;
    },
    async backwardParticipant<T>() {
      const currentIndex = this.participants.findIndex(
        (participant) => participant.key === this.selectedParticipant.key
      );
      const previousIndex = currentIndex - 1;
      if (previousIndex < 0) {
        const backwardParticipant = this.participants[
          this.participants.length - 1
        ];
        this.selectedParticipant = backwardParticipant;
        return backwardParticipant;
      }
      const previousParticipant = this.participants[previousIndex];
      this.selectedParticipant = previousParticipant;
      return previousParticipant;
    },
  },
  getters: {
    getSelectedParticipant(state) {
      return state?.selectedParticipant;
    },
  },
});
